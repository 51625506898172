import { Profile } from '../../../lib/profiles/types';
import { MainLayout } from '../../../commons/components/layouts';
// import { privateMenu } from './privateMenu';
import { publicMenu } from '../Public';

// TODO: fix private profile
export const privateProfile: Profile = {
  name: 'private',
  component: MainLayout,
  menu: publicMenu,
};
