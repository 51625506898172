export const PROMOTION_SLUG: { [k: string]: number } = {
  'novatech-vueltaalcole': 1,
  'bancoprovincia-copaamerica': 2,
  'correocompras-vueltaalcole': 3,
  'bancoprovincia-diadelpadre': 5,
};

export const PROMOTION_ID_SLUG: { [k: number]: string } = {
  1: 'novatech-vueltaalcole',
  2: 'bancoprovincia-copaamerica',
  3: 'correocompras-vueltaalcole',
  5: 'bancoprovincia-diadelpadre',
};

export const PROMOTION_ID_TITLE: { [k: number]: string } = {
  1: 'Vuelta al Cole',
  2: 'Copa América',
  3: 'Correo Compras',
  5: 'Día del Padre',
};

export const PROMOTION_VUELTA_AL_COLE_TYPE: {
  NOVATECH: number;
  BANCO_PROVINCIA: number;
  CORREO_COMPRAS: number;
  BANCO_PROVINCIA_DIA_DEL_PADRE: number;
} = {
  NOVATECH: 1,
  BANCO_PROVINCIA: 2,
  CORREO_COMPRAS: 3,
  BANCO_PROVINCIA_DIA_DEL_PADRE: 5,
};

export const isBancoProvincia = (id: number) => [PROMOTION_VUELTA_AL_COLE_TYPE.BANCO_PROVINCIA, PROMOTION_VUELTA_AL_COLE_TYPE.BANCO_PROVINCIA_DIA_DEL_PADRE].includes(id);
