import React, { FunctionComponent } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useLocation } from 'react-router-dom';
import { useProfileContext } from 'src/lib/profiles/ProfileContext';
import { SideMenu, ColorBar, Header } from '..';
import { MenuCollection } from '../../ui/menu';
import { useRouterContext } from '../../../lib/router';

const useStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    height: '100vh',
    margin: -8,
  },
  menuContainer: {
    flexDirection: 'row',
    flex: 1,
    paddingLeft: 25,
    paddingRight: 25,
  },
  sideAndContent: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface NoMenuLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
}

export const NoMenuLayout: FunctionComponent<NoMenuLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const { metadata } = useRouterContext();
  return (
    <div className={classes.container}>
      <ColorBar />
      <div className={classes.menuContainer}>
        <Header title="Solicitar Usuario" backButton backToProfile="sinEstablecimiento" />
        <main>
          <div />
          {children}
        </main>
      </div>
    </div>
  );
};
