import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { grey, primary } from '../../../../../theme/colors';

type AnyObject = { [k: string]: any };

const useStyles = makeStyles({
  root: {
    background: grey.background,
    color: grey.dark,
    fontSize: 13,
    fontWeight: 600,
    '&.MuiTableSortLabel-icon': {
      color: primary.lightBlue,
    },
  },
});

export function EnhancedTableHead({ order, orderBy, onRequestSort, headCells }: AnyObject) {
  const classes = useStyles();
  const createSortHandler = (property: any) => (event: AnyObject) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          ({ id, label, icon, render, orderById, noSort, hideSortIcon, ...styles }: AnyObject) => (
            <TableCell
              key={id}
              align="left"
              sortDirection={orderBy === id ? order : false}
              {...styles}
              classes={{
                root: classes.root,
              }}
            >
              <TableSortLabel
                hideSortIcon={hideSortIcon}
                active={orderBy === id || orderBy === orderById}
                direction={orderBy === id || orderBy === orderById ? order : 'asc'}
                onClick={!noSort ? createSortHandler(orderById || id) : undefined}
                IconComponent={!hideSortIcon ? StyledUnfoldMoreIcon : EmptyComponent}
              >
                {icon ? icon() : label}
              </TableSortLabel>
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
}

const EmptyComponent = () => <></>;

const StyledUnfoldMoreIcon = () => <UnfoldMoreIcon color="primary" />;
