export const SET_TEST = 'setTest';

interface GeneralActions {
  type: string;
  payload?: any;
}

export type GeneralState = {
  test: string;
};

export const generalInitialState: GeneralState = {
  test: '',
};

export const generalReducer = (state = generalInitialState, action: GeneralActions) => {
  switch (action.type) {
    case SET_TEST:
      return {
        ...state,
        test: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
