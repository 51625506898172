export interface IGroups {
  id: string; // id rol_user
  name: string; // name rol
  rol: {
    id: string;
    name: string;
  };
}
export interface ISterUser {
  id: string;
  username: string;
  domain: string;
  groupSelected?: IGroups;
  groups: Array<IGroups>;
  token: string;
}
export interface ResponseLogin {
  user: ISterUser;
  isGuest: boolean;
}

class SessionChangeEmitterRaw {
  // eslint-disable-next-line @typescript-eslint/ban-types
  subscriptions: Function[];

  constructor() {
    this.subscriptions = [];
  }

  subscribe(fn: (params: { isLogged: boolean; user: ISterUser }) => any): () => void {
    this.subscriptions.push(fn);

    return () => this.unsubscribe(fn);
  }

  unsubscribe(fn: (params: any) => any): void {
    this.subscriptions = this.subscriptions.filter((item) => item !== fn);
  }

  emitChange<F extends any[]>(...args: F): void {
    this.subscriptions.forEach((func) => func(...args));
  }
}

export const SessionChangeEmitter = new SessionChangeEmitterRaw();
