import React, { createContext, useReducer, useContext, useMemo } from 'react';
import { generalInitialState, generalReducer, GeneralState, SET_TEST } from './reducer';

interface GeneralContextProps {
  children: JSX.Element;
}

export const GeneralContextProvider = ({ children }: GeneralContextProps) => {
  const [generalState, dispatcher] = useReducer(generalReducer, generalInitialState);

  const dispatch = useMemo(
    () => ({
      // ejemplo
      [SET_TEST]: (payload: string) => dispatcher({ type: SET_TEST, payload }),
    }),
    [],
  );

  const contextValue = useMemo(() => ({ generalState, dispatch }), [generalState, dispatch]);

  return <GeneralContext.Provider value={contextValue}>{children}</GeneralContext.Provider>;
};

export interface GeneralContextValue {
  generalState: GeneralState,
  dispatch: { [k: string]: (payload: any) => void },
}

export const GeneralContext = createContext<GeneralContextValue>({ generalState: generalInitialState, dispatch: {} });

export const useGeneralContext = () => useContext<GeneralContextValue>(GeneralContext);
