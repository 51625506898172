import React, {useCallback} from 'react';
import {Card, CardContent, CardMedia, Typography} from "@material-ui/core";

export interface CardButtonProps<T> {
  title: string | JSX.Element;
  image?: string;
  data: T;
  onClick: (data: T) => any;
}

export function CardButton<T = any>({ title, image, onClick, data }: CardButtonProps<T>) {
  const onCardClick = useCallback(() => onClick(data), [data, onClick]);
  return (
    <Card onClick={onClick && onCardClick}>
      {!!image && (
        <CardMedia
          component="img"
          alt="Conf image"
          height="200"
          image={image}
        />
      )}
      <CardContent>
        {typeof title !== 'string' ? title : (
          <Typography variant="h1">{title}</Typography>
        )}
      </CardContent>
    </Card>
  );
}
