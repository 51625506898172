import {Rules} from "../../../../lib/permission/types";
import {SIDEMENU_ID} from "../../const";

export const adminRuleSet: Rules = {
  idRule: 'rol-6',
  routes: [
    // aqui van las restricciones de rutas
  ],
  blockers: [
    // aqui van las restricciones de blockers que se usan para componentes y ui
  ],
  sideMenu: [
    // aqui van las restricciones del menu
  ],
  tabBars: [
    // aqui van las restricciones de tabs
  ],
};
