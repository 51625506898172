import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ButtonTypesProps, FooterForm } from '../FooterForm';
import { Loading } from '../Loading';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
  },
  spacer: {
    width: '100%',
    height: 74,
  },
});

export interface FooterButtonsProps {
  buttons: ButtonTypesProps[];
  loading?: boolean;
}

export const FooterButtons = ({ buttons, loading }: FooterButtonsProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.spacer} />
      <Grid className={classes.footer}>
        {loading ? <Loading /> : <FooterForm buttonConfig={buttons} />}
      </Grid>
    </>
  );
};
