/* eslint-disable class-methods-use-this */
import { Database, Libby, RESTSessionStrategy, RESTStoreStrategy } from '@phinxlab/libby-rest-web';
import { API_URL } from '../../config';
import { SessionChangeEmitter } from './session';
import { TokenManager } from './TokenManager';
import { PromotionDAO, PromotionItemDAO, PromotionOperationDAO } from './dao';

class DatabaseStoreRaw {
  chinoTokenEnvironmet: string;

  url: string;

  constructor() {
    this.chinoTokenEnvironmet = 'SterApp';
    this.url = API_URL;
    // this.url = 'http://localhost:9099';
  }

  addSession(): void {
    const Session = RESTSessionStrategy.init(this.url);
    Session.setTokenManager(TokenManager.create());
    Database.addSessionStrategy(Session);
    Session.addSessionListener({
      // TODO: remove any type
      newSessionState: (state: any) => {
        const { user } = Session;
        const isLogged: boolean = !!user && user.isGuest === false;
        SessionChangeEmitter.emitChange({ isLogged, user, state });
      },
    });
    Database.addSessionStrategy(Session);
  }

  register(): void {
    Database.register(new RESTStoreStrategy('promotion', '/api/business/promotion', 'promotionId'), PromotionDAO);
    Database.register(new RESTStoreStrategy('promotion_item', '/api/business/promotionitem', 'promotionItemId'), PromotionItemDAO);
    Database.register(new RESTStoreStrategy('promotion_operation', '/api/business/promotionoperation', 'promotionOperationId'), PromotionOperationDAO);
  }

  init(): void {
    Libby.App = this.chinoTokenEnvironmet;
    this.addSession();
    this.register();
  }
}

export const DatabaseStore = new DatabaseStoreRaw();
