import React from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Card, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { primary, secondary } from '../../../theme/colors';

interface ToastTypeProps {
  id?: number;
  title: string;
  subtitle: string;
  customStyleToast?: any;
  customStyleTitle?: any;
  customStyleSubtitle?: any;
  status?: string;
}

const backgroundColor: Record<string, any> = {
  success: primary.lightBlue,
  error: secondary.toastError,
  information: primary.lightBlue,
  default: secondary.toastDefault,
};

export const ToastMessage = React.forwardRef((props: ToastTypeProps, ref: any) => {
  const { closeSnackbar } = useSnackbar();
  const {
    id,
    title,
    subtitle,
    customStyleToast,
    customStyleTitle,
    customStyleSubtitle,
    status = 'default',
  } = props;

  const toastStyles = makeStyles(() => ({
    toastMain: {
      background: backgroundColor[status],
      display: 'relative',
      textTransform: 'none',
      borderRadius: 4,
      padding: 20,
      lineHeight: 1,
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
      ...customStyleToast,
    },
    title: {
      fontWeight: 'normal',
      color: 'rgba(38, 38, 38, 0.85)',
      ...customStyleTitle,
    },
    subtitle: {
      fontSize: 11,
      fontWeight: 'normal',
      color: 'rgba(0, 0, 0, 0.65);',
      ...customStyleSubtitle,
    },
    iconClosed: {
      position: 'absolute',
      right: 1,
      top: -5,
    },
  }));

  const classes = toastStyles();

  const handleDismiss = () => {
    closeSnackbar(id);
  };

  return (
    <Card className={classes.toastMain} ref={ref}>
      <>
        <IconButton onClick={handleDismiss} className={classes.iconClosed}>
          <CloseIcon style={{ fontSize: 17 }} />
        </IconButton>
      </>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>
    </Card>
  );
});
