import palette from './palette';

type Typography = { [k: string]: any };

const typography: Typography = {
  useNextVariants: true,
  fontFamily: 'Rubik',
  fontWeight: 500,
  letterSpacing: -0.3,
  h1: {
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '28.8px',
  },
  h2: {
    fontSize: '28px',
    lineHeight: '28.8px',
  },
  h3: {
    fontSize: '24px',
    lineHeight: '19.2px',
  },
  h4: {
    fontSize: '20px',
    lineHeight: '19.2px',
  },
  h5: {
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  subtitle1: {
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '25px',
  },
  subtitle2: {
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  body1: {
    color: palette.text.primary,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
  },
  body2: {
    color: palette.text.primary,
    fontSize: '12px',
    letterSpacing: '-0.04px',
    lineHeight: '14px',
  },
  button: {
    fontSize: '14px',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '12px',
    letterSpacing: '0.3px',
    lineHeight: '16px',
  },
  navbarIcon: {
    height: '32px',
    width: '32px',
    fontSize: '32px',
  },
};

export default typography;
