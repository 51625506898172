import React from 'react';
import { Grid, GridItemsAlignment, Typography } from '@material-ui/core';
import { GridSpacing } from '@material-ui/core/Grid/Grid';
import { SPACING } from '../../const';

export interface TitleValueProps {
  title: string | JSX.Element | JSX.Element[];
  value: string | JSX.Element | JSX.Element[];
  spacing: GridSpacing;
  alignItems?: GridItemsAlignment;
}

export const TitleValue = ({ title, value, spacing = SPACING.COMMON, alignItems }: TitleValueProps) => (
  <Grid container spacing={spacing} alignItems={alignItems}>
    <Grid item>
      {typeof title !== 'string' ? title : (
        <Typography>{title}</Typography>
      )}
    </Grid>
    <Grid item>
      {typeof value !== 'string' ? value : (
        <Typography>{value}</Typography>
      )}
    </Grid>
  </Grid>
);
