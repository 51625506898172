// Material helpers
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import {CommonColors, Palette} from "@material-ui/core/styles/createPalette";
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

export interface AppCommonColors extends CommonColors {
  muted: string,
  neutral: string,
}

export interface AppPalette extends Palette {
  common: AppCommonColors,
}

export interface AppTheme extends Theme {
  palette: AppPalette,
}

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
}) as AppTheme;

export default theme;
