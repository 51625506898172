import { createContext, useContext } from 'react';

export type UserRole = {
  roleUserId: string | number;
  roleName: string;
};

export type UserInfo = {
  id: string;
  name: string;
  email: string;
};

export type RolesContextValue = {
  selectedRole: UserRole;
  setSelectedRole: (selectedRole: UserRole) => void;
  deselectRole: () => void;
  setUserRoles: (userRoles: UserRole[]) => void;
  userRoles: UserRole[];
  userInfo: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
};

export const RolesContext = createContext<RolesContextValue>({
  selectedRole: {
    roleUserId: '',
    roleName: ''
  },
  setSelectedRole: () => {},
  deselectRole: () => {},
  userRoles: [],
  setUserRoles: () => {},
  userInfo: {
    id: '',
    name: '',
    email: '',
  },
  setUserInfo: () => {},
});

export const useRolesContext = () => useContext(RolesContext);
