import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { App } from './App';
import TranslationService from './commons/services/translation/service';
import { PhinxAppProvider } from './lib/profiles/PhinxApp';
import { profiles } from './platform/profiles';
import { ToastProvider } from './commons/components/ToastMessage/ToastProvider';
import { RolesContextProvider } from './context/RolesContext/RolesContextProvider';
import { GeneralContextProvider } from './context/GeneralContext/GeneralContext';
import theme from './theme';
import { allPermission } from './platform/permission/allPermission';

document.getElementById('initial-loading')?.remove();
document.getElementById('banner-desktop')?.remove();
document.getElementById('banner-mobile')?.remove();

TranslationService.initialize();
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ToastProvider>
              <PhinxAppProvider profiles={profiles} rules={allPermission} profile="public">
                <RolesContextProvider>
                  <GeneralContextProvider>
                    <>
                      <CssBaseline />
                      <App />
                    </>
                  </GeneralContextProvider>
                </RolesContextProvider>
              </PhinxAppProvider>
            </ToastProvider>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
