import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PromotionItemDAO extends LibbyFetchDAO {
  constructor() {
    super('promotion_item', 'promotionItemId');
  }

  getByPromotionId(promotionId: number | string) {
    return this.query()
      .equals('promotion', promotionId)
      .equals('available', true)
      .orderBy('product.priority')
      .run();
  }
}
