import React, { FunctionComponent, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AnyObject } from 'src/commons/types';
import { makeStyles } from '@material-ui/styles';
import { primary } from 'src/theme/colors';
import { PermissionBlocker } from 'src/lib/permission';

const useStyles = makeStyles({
  menuText: {
    fontFamily: 'Open Sans',
    '&:hover': {
      color: primary.lightBlue,
    },
  },
});

export type HideOptionFunction = (data: any) => boolean;

export interface OptionsModalOption<T = any> {
  label: string;
  onClick: (data: T) => void;
  hide?: boolean | HideOptionFunction;
  id?: number;
}

export type OptionsModalProps<T = any> = {
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  options: Array<OptionsModalOption<T>>;
  data?: any;
};

export const OptionsModal: FunctionComponent<OptionsModalProps> = ({
  color = 'primary',
  options,
  data,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleMenu = (event: AnyObject) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="change language"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color={color}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-appbar-language"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {options
          .filter((item) => {
            if (item.hide === undefined) {
              return true;
            }
            return typeof item.hide === 'boolean' ? !item.hide : !item.hide(data);
          })
          .map((item: OptionsModalOption, index: number) => (
            <PermissionBlocker id={item.id || 'unset'}>
              <MenuItem onClick={() => item.onClick(data)} className={classes.menuText} key={index}>
                {item.label}
              </MenuItem>
            </PermissionBlocker>
          ))}
      </Menu>
    </>
  );
};
