import React, { FunctionComponent, MouseEventHandler } from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Dialog, DialogTitle } from '@material-ui/core';
import typography from 'src/theme/typography';
import { grey } from 'src/theme/colors';

interface ModalServicesProps {
  // cancelText: React.ReactNode;
  // confirmText: React.ReactNode;
  customModalServices?: any;
  customTheme: { [k: string]: any } | null;
  onCancel: MouseEventHandler;
  // onConfirm: MouseEventHandler;
  open: boolean;
  renderComponent: JSX.Element | null;
  // sizeButton?: string;
  sizeWidth?: any;
  title: React.ReactNode;
  // typeButtonCancel?: string;
  // typeButtonConfirm?: string;
}

const dummyFunc = () => {};

export const ModalServices: FunctionComponent<ModalServicesProps> = ({
  title = 'Confirmation Dialog',
  renderComponent = null,
  sizeWidth = false,
  customModalServices,
  open = false,
  customTheme = null,
  onCancel = dummyFunc,
}) => {
  const modalServicesStyles = makeStyles(() => ({
    title: {
      fontSize: 28,
      fontWeight: 'normal',
      color: grey.medium,
      fontFamily: typography.fontFamily,
    },
    dialog: {
      margin: 'auto',
      '& .MuiPaper-rounded': {
        borderRadius: 0,
      },
      '& .MuiDialogActions-root': {
        boxShadow: '0px -3px 3px rgba(0, 0, 0, 0.1);',
        height: 36,
        paddingRight: 36,
      },
      '& .MuiDialogContent-root': {
        padding: '8px 10px',
      },
      ...customModalServices,
    },
  }));

  const classes = modalServicesStyles();
  const modalComponent = (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth={sizeWidth}
      className={classes.dialog}
    >
      <DialogTitle className={classes.title} disableTypography>
        {title}
      </DialogTitle>
      {renderComponent}
    </Dialog>
  );
  return !customTheme ? (
    modalComponent
  ) : (
    <ThemeProvider theme={customTheme}>{modalComponent}</ThemeProvider>
  );
};
