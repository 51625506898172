import React, { useCallback, useState, Fragment } from 'react';
import { List, Collapse, useTheme } from '@material-ui/core';
import { MenuDivider, MenuItem } from '../MenuItem';
import { MenuGroupItem } from './MenuGroupItem';

export type MenuTreeState = {
  [k: string]: boolean;
};

export type MenuGroupProps = {
  onOpen: (item: MenuItem) => void;
  menus: Array<MenuItem | MenuDivider>;
  level?: number;
  open: boolean;
};

const isMenuItem = (menu: MenuItem | MenuDivider): menu is MenuItem =>
  (menu as MenuDivider).divider === undefined;

export const MenuGroup = ({ menus, level, open, onOpen }: MenuGroupProps) => {
  const [treeStates, setTreeStates] = useState<MenuTreeState>({});
  const theme = useTheme();
  const localLevel: number = level || 0;
  const onClick = useCallback(
    (menu: MenuItem) => {
      if (menu.children.length > 0) {
        setTreeStates((prev) => ({ ...prev, [menu.key]: !prev[menu.key] }));
      } else {
        onOpen(menu);
      }
    },
    [onOpen],
  );
  return !menus.length ? null : (
    <Collapse
      in={open}
      timeout="auto"
      unmountOnExit
      style={{ paddingLeft: theme.spacing(localLevel) }}
    >
      <List>
        {menus.filter(isMenuItem).map((menuItem) => (
          <Fragment key={menuItem.key}>
            <MenuGroupItem onClick={onClick} menuItem={menuItem} open={treeStates[menuItem.key]} />
            <MenuGroup
              menus={menuItem.children}
              level={localLevel + 1}
              open={treeStates[menuItem.key]}
              onOpen={onOpen}
            />
          </Fragment>
        ))}
      </List>
    </Collapse>
  );
};
