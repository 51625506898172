// eslint-disable-next-line max-classes-per-file
import React, { Component, ComponentType } from 'react';
import { render } from 'react-dom';
import {AnyObject} from "../types/types";

type AnyProps = {
  [k: string]: any;
};

export type AsServiceOptions = {
  activeProp: string;
  resolveProp: string;
  rejectProp: string;
  forceResolveOnReject: boolean;
  rejectValue?: any | null;
  resolveValue?: any;
  maxWidth?: any
};

export interface IShow {
  show(props: AnyObject): Promise<any>;
  getCreateProps(): any;
}

const asService = ({
  activeProp,
  resolveProp,
  rejectProp,
  forceResolveOnReject = false,
  rejectValue = null,
  resolveValue,
}: AsServiceOptions) => (Comp: ComponentType<any>) => {
  type AsServiceProps = {
    createProps: AnyProps;
    setRef?: (service: AsService) => void;
  };

  type AsServiceState = {
    isOpen: boolean;
    innerProps: AnyProps;
  };

  class ServiceInstanceHandler {
    get ref(): AsService {
      return this._ref;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    private _ref: AsService = new AsService({ createProps: {} });

    constructor(props: any) {
      this.init(props);
    }

    init(props: AnyProps = {}) {
      const containerElement = document.createElement('div');
      document.body.appendChild(containerElement);
      return render(
        <AsService
          createProps={props}
          setRef={(service) => {
            this._ref = service;
          }}
        />,
        containerElement,
      );
    }
  }

  let resolve: any;
  let reject: any;
  class AsService extends Component<AsServiceProps, AsServiceState> implements IShow {
    static create(props = {}): AsService {
      const instance = new ServiceInstanceHandler(props);
      return instance.ref;
    }

    constructor(props: AsServiceProps) {
      super(props);

      this.state = {
        isOpen: false,
        innerProps: {},
      };

      this.handleCancel = this.handleCancel.bind(this);
      this.handleConfirm = this.handleConfirm.bind(this);
      this.show = this.show.bind(this);
    }

    componentDidMount(): void {
      const { setRef } = this.props;
      if (setRef) {
        setRef(this);
      }
    }

    handleCancel(...params: any[]) {
      this.setState({ isOpen: false });
      const rejectionValue = rejectValue !== undefined ? [rejectValue] : params;
      if (forceResolveOnReject) {
        resolve(...rejectionValue);
      } else {
        reject(...rejectionValue);
      }
    }

    handleConfirm(...params: any[]) {
      this.setState({ isOpen: false });
      if (resolveValue !== undefined) {
        resolve(resolveValue);
      } else {
        resolve(...params);
      }
    }

    getCreateProps() {
      // eslint-disable-next-line react/destructuring-assignment
      return this.props.createProps;
    }

    show(props = {}) {
      const { createProps } = this.props;
      const innerProps = { ...createProps, ...props };
      this.setState({ isOpen: true, innerProps });
      return new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
    }

    render() {
      const { isOpen, innerProps } = this.state;
      const compProps = {
        [activeProp]: isOpen,
        [resolveProp]: this.handleConfirm,
        [rejectProp]: this.handleCancel,
        ...innerProps,
      };
      return <Comp {...compProps} />;
    }
  }

  return AsService;
};

export default asService;
