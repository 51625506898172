import moment from 'moment';
import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PromotionDAO extends LibbyFetchDAO {
  constructor() {
    super('promotion', 'promotionId');
  }

  getActivePromotions() {
    return this.query().orderBy('promotionId').higher('validUntil', moment().toISOString()).run();
  }
}
