import React, { FunctionComponent, MouseEventHandler } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { primary } from 'src/theme/colors';

type ConfirmDialogProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  cancelText: React.ReactNode;
  confirmText: React.ReactNode;
  open: boolean;
  onCancel: MouseEventHandler;
  onConfirm: MouseEventHandler;
  customTheme: { [k: string]: any } | null;
};

const styles = {
  title: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: 0,
  },
};

const dummyFunc = () => {};

const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
  title = 'Confirmation Dialog',
  content = 'Are you sure of perform this action?',
  cancelText,
  confirmText = 'Confirm',
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  customTheme = null,
}) => {
  const dialog = (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <span style={{ ...styles.title }}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelText && (
          <Button
            onClick={onCancel}
            variant="text"
            style={{ textTransform: 'capitalize', color: primary.paleBlueStrong }}
          >
            {cancelText}
          </Button>
        )}
        {confirmText && (
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            autoFocus
            style={{ textTransform: 'capitalize' }}
          >
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};

export default ConfirmDialog;
