import React, { FunctionComponent } from 'react';
import { SnackbarProvider } from 'notistack';

export const ToastProvider: FunctionComponent<any> = ({ children }) => (
  <SnackbarProvider
    preventDuplicate
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    {children}
  </SnackbarProvider>
);
