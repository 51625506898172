import React from 'react';
import { MenuItem } from '../../../../commons/ui/menu';

const getImport = (name: string, url: any) => React.lazy(async () => {
  const { [name]: Comp } = await url;
  return { default: Comp };
});

export const routes: MenuItem[] = [
  {
    key: 'promotionsItemCheckout',
    basePath: '/lista/:promotionId/:promotionItemId/confirm',
    target: {
      component: getImport('PromotionsItemConfirm', import('../../../../screens/Public/Promotions/PromotionsItemConfirm/PromotionsItemConfirm')),
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Promotions Item Checkout',
    children: [],
  },
  {
    key: 'promotionsItemCheckout',
    basePath: '/lista/:promotionId/:promotionItemId/checkout',
    target: {
      component: getImport('PromotionsItemCheckout', import('../../../../screens/Public/Promotions/PromotionsItemCheckout/PromotionsItemCheckout')),
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Promotions Item Checkout',
    children: [],
  },
  {
    key: 'promotionsItemDetails',
    basePath: '/lista/:promotionId/:promotionItemId',
    target: {
      component: getImport('PromotionsItemDetails', import('../../../../screens/Public/Promotions/PromotionsItemDetails/PromotionsItemDetails')),
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Promotions Item Details',
    children: [],
  },
  {
    key: 'promotionsDetails',
    basePath: '/lista/:promotionId',
    target: {
      component: getImport('PromotionsDetails', import('../../../../screens/Public/Promotions/PromotionsDetails/PromotionsDetails')),
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Promotions Details',
    children: [],
  },
  {
    key: 'promotionsList',
    basePath: '/lista',
    target: {
      component: getImport('PromotionsList', import('../../../../screens/Public/Promotions/PromotionsList/PromotionsList')),
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Promotions List',
    children: [],
    default: true,
  },
  {
    key: 'promotionConfirmationBuyMobbex',
    basePath: '/confirmacioncompra/:paymentReference',
    target: {
      component: getImport('PromotionsItemConfirm', import('../../../../screens/Public/Promotions/PromotionsItemConfirm/PromotionsItemConfirm')),
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Promotions List',
    children: [],
  },
  {
    key: 'promotionConfirmationBuyMobbex',
    basePath: '/confirmacioncompra/',
    target: {
      component: getImport('PromotionsItemConfirm', import('../../../../screens/Public/Promotions/PromotionsItemConfirm/PromotionsItemConfirm')),
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Promotions List',
    children: [],
  },
];
