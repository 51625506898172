import palette from '../palette';
import typography from '../typography';
import { primary, grey } from '../colors';

type MuiInputBaseType = { [k: string]: any };

const MuiInputBase: MuiInputBaseType = {
  root: {
    color: palette.text.secondary,
    border: `1px solid ${grey.normal}`,
    borderRadius: 4,
    fontSize: 13,
    fontFamily: typography.fontFamily,
    backgroundColor: primary.white,
    padding: '4px 7px',
  },
};

export default MuiInputBase;
