import React, { useState } from 'react';
import { Menu, Typography, Grid, Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import { UserAvatar } from 'src/commons/components/UserAvatar';
import { AnyObject } from 'src/commons/types';
import { useRolesContext } from 'src/context/RolesContext/RolesContext';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import { primary, grey } from 'src/theme/colors';

const useStyles = makeStyles({
  menuText: {
    fontFamily: 'Open Sans',
    '&:hover': {
      color: primary.lightBlue,
    },
  },
  mainContainer: {
    width: 354,
    borderRadius: 10,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 25,
  },
  userNameContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    padding: 20,
  },
  mailText: {
    color: grey.heading,
    fontSize: 14,
  },
  userText: {
    color: grey.heading,
    fontSize: 14,
    fontWeight: 700,
  },
  listContainer: {
    padding: 20,
  },
  footerContainer: {
    paddingLeft: 30,
    paddingRight: 30,
    padding: 10,
    alignItems: 'center',
    height: 90,
  },
  footerMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 15,
  },
  footerText: {
    color: grey.heading,
    fontSize: 14,
    paddingLeft: 15,
    '&:hover': {
      color: primary.lightBlue,
    },
  },
  iconStyle: {
    fontSize: 20,
    color: grey.heading,
  },
});

type OptionsModalProps = {
  logout: () => void;
};

export const UserCard = ({ logout }: OptionsModalProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const roleContext = useRolesContext();
  const handleMenu = (event: AnyObject) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="change language"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
      >
        <UserAvatar name={roleContext.userInfo.name} avatarWidth={28} avatarHeight={28} />
      </IconButton>
      <Menu
        id="menu-appbar-language"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.mainContainer }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <Grid className={classes.headerContainer}>
          <UserAvatar name={roleContext.userInfo.name} avatarWidth={65} avatarHeight={65} />
          <Grid className={classes.userNameContainer}>
            <Typography className={classes.userText}>{roleContext.userInfo.name}</Typography>
            <Typography className={classes.mailText}>{roleContext.userInfo.email}</Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid className={classes.footerContainer}>
          <IconButton className={classes.footerMenu}>
            <SettingsIcon className={classes.iconStyle} />
            <Typography className={classes.footerText}> Configuración </Typography>
          </IconButton>
          <IconButton className={classes.footerMenu} onClick={logout}>
            <ExitToAppSharpIcon className={classes.iconStyle} />
            <Typography className={classes.footerText}> Cerrar Sesión </Typography>
          </IconButton>
        </Grid>
      </Menu>
    </>
  );
};
