import React from 'react';
import {Dialog} from "@material-ui/core";
import {Loading} from "../Loading";

export interface LoadingModalProps {
  open: boolean;
}

export const LoadingModal = ({ open }: LoadingModalProps) => (
  <Dialog open={open}>
    <Loading />
  </Dialog>
);
