import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { GoogleAnalytic } from 'src/app/services/GoogleAnalytic';
import { FacebookPixel } from 'src/app/services/FacebookPixel';
import { DatabaseStore } from './platform/libby/DatabaseStore';
import { ISterUser, SessionChangeEmitter } from './platform/libby/session';
import { PhinxApp } from './lib/profiles/PhinxApp';
import { useProfileContext } from './lib/profiles/ProfileContext';
import { Loading } from './commons/components/Loading';

// Initialize Libby
DatabaseStore.init();

if (window.location.pathname.slice(18, 25) === '/novate'){
console.log("inicializado")
FacebookPixel.initialize();
}
export const App: FunctionComponent = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [initialProfileLoaded, setInitialProfileLoaded] = useState(false);
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);
  const { setProfile } = useProfileContext();
  const [gaInitialized, setGaIntialized]=useState<string | undefined>('')

  useEffect(
    () =>
      SessionChangeEmitter.subscribe(
        ({ isLogged: logged, user }: { isLogged: boolean; user: ISterUser }) => {
          // Esta logica es para manejar la seleccion del rol y la seleccion de las reglas de permisos
          // if (user.groupSelected) {
          //   desactivateAllRulesSets();
          //   activateRulesSet(`rol-${user.groupSelected.rol.id}`);
          //   activateRulesSet(`nivel-${user.groupSelected.nivel.idNivel}`);
          //   setSelectedRole({});
          // } else {
          //   desactivateAllRulesSets();
          // }
          setIsLogged(logged);
          setTimeout(() => setIsFirstLoading(false), 0);
        },
      )
    ,
    [],
  );

  useEffect(()=>{
    const pathId = [
      { path: '/bancop', gaId: 1 },
      { path: '/novate', gaId: 2 },
      { path: '/correo', gaId: 3 },
    ];

    function getId() {
      for (let i = 0; i < pathId.length; i++) {
        if (window.location?.pathname?.slice(18, 25) === pathId[i].path) {
          return `UA-190202452-${pathId[i].gaId}`;
        }
      }
      return `UA-190202452-${pathId[1].gaId}`;
    }
    const gAnalyticsID = getId();

    if(gAnalyticsID !==gaInitialized){
    GoogleAnalytic.initialize(gAnalyticsID);
    setGaIntialized(gAnalyticsID)
    }
  }, [gaInitialized])

  useEffect(() => {
    if (!isFirstLoading) {
      if (!isLogged) {
        setProfile('public');
      } else {
        setProfile('private');
      }
      setInitialProfileLoaded(true);
    }
  }, [isFirstLoading, isLogged, setProfile]);

  return !initialProfileLoaded ? <Loading /> : <PhinxApp />;
};
