import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const promotionsMenu: MenuItem = {
  key: 'promotions',
  basePath: '/promociones',
  enabled: true,
  icon: 'List',
  text: 'Promotions',
  children: routes,
  default: true,
};
