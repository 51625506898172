import React from 'react';
import { Grid } from '@material-ui/core';

export interface LoadingProps {
  className?: string;
  width?: number;
}

export const Loading = ({ className = '', width = 50 }: LoadingProps) => (
  <Grid className={className} container justify="center" alignItems="center">
    <img src='https://img.novatechstore.com.ar/Assets/images/loading.gif' width={width} alt="loading" />
  </Grid>
);
