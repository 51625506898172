import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PromotionOperationDAO extends LibbyFetchDAO {
  constructor() {
    super('promotion_operation', 'promotionOperationId');
  }

  async getByPaymentReference(paymentReference: string) {
    const [data] = await this.query().equals('paymentReference', paymentReference).run();
    return data;
  }
}
