import React, {FunctionComponent} from 'react';
import { makeStyles } from '@material-ui/core';
import { MenuCollection } from '../../ui/menu';

const useStyles = makeStyles(() => ({
  mainContainer: {
    minHeight: 800,
  },
}));

interface MainLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
}

export const MainLayout: FunctionComponent<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <div>
      <main className={classes.mainContainer}>
        {children}
      </main>
    </div>
  );
};
